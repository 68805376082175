* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto Mono";
  font-size: 22px;
  font-size: 16px;
}
footer {
  padding: 20px 0;
}

footer.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

footer.container p {
  font-size: 14px;
  margin: 0;
}

.social-media a {
  color: black;
  margin-right: 10px;
  font-size: 24px;
}

.social-media a:hover {
  color: #007bff;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

header.scrolled {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* JavaScript to add "scrolled" class when header is scrolled */

.logo {
  height: 100px;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-right: 20px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  display: block;
  padding: 10px 20px;
  background-color: transparent;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

main {
  display: flex;
  flex-direction: column;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 70px;
}

.section1 {
  background-image: url("../images/splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.section1 p {
  color: white;
  font-size: 2em;
}

.section1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.section1 h1 {
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
}
.about {
  background-color: #f5f5f5;
  padding: 30px 0;
}

.section2 {
  background-color: #f7f7f7;
}

.section3 {
  display: inline-block;
  background-color: #fff;
}

.section1,
.section2,
.section3 {
  padding: 100px 0;
}
